import { useAuthStore } from '@/stores/auth';
import router from '..';
import { AUTH_ROUTES, PRIVATE_ROUTES } from '../routes';
import { HOME_ROUTE_NAME } from '@/global/globalVariables';
import { useNewProfileStore } from '@/stores/profile';
import { flattenRoutes } from '@/helpers/convert';

const usePermissionGuard = () => {
  const userStore = useAuthStore();
  const profileStore = useNewProfileStore();

  // faltten the PRIVATE_ROUTES array to include all nested routes
  const flattenedPrivateRoutes = flattenRoutes(PRIVATE_ROUTES);

  router.beforeEach((to, from, next) => {
    const isAuthenticated = !!userStore.getToken;
    const isPrivateRoute = flattenedPrivateRoutes.some(route => route.name === to.name);

    // redirect to dashboard if logged in and trying to access AUTH_ROUTES
    if (AUTH_ROUTES.some(route => route.name === to.name)) {
      if (isAuthenticated) {
        return next({ name: HOME_ROUTE_NAME });
      }
    }

    // redirect to login if not logged in and trying to access PRIVATE_ROUTES
    if (isPrivateRoute && !isAuthenticated) {
      return next({ name: 'LoginView' });
    }

    // redirect to login if not logged in and trying to access routes that require authentication
    if (to.meta.isAuth && !isAuthenticated) {
      return next({ name: 'LoginView' });
    }

    // prevent user from accessing permission restricted pages (e.g. broker specific pages)
    if (to.meta.permission && profileStore.getUserType !== to.meta.permission.toLowerCase()) {
      return next({ name: HOME_ROUTE_NAME });
    }

    // If none of the above conditions are met, proceed normally
    next();
  });
};

export default usePermissionGuard;
