import './assets/css/main.css';
import App from './App.vue';
import { createApp } from 'vue';
import { setupNaive, setupNaiveUIMeta } from '@/plugins/naive';
import { setupI18n } from '@/i18/index.js';
import setupPiniaStore from '@/stores/pinia';
import { setupRouter } from '@/router';
import { createLoadingSpinner, validateUserAndLoadProfile } from '@/api/validateUser';

/**
 * Sets up all the features of the app.
 * @param {import('vue').App} app - The Vue app instance
 */
function setupAppFeatures(app) {
  setupNaive(app);
  setupPiniaStore(app);
  setupI18n(app);
}

/**
 * Initializes the Vue application.
 * This function sets up the app, validates the user, and mounts the app.
 * @returns {Promise<void>}
 */
async function initApp() {
  const app = createApp(App);

  setupAppFeatures(app);

  // start app loading
  const loader = createLoadingSpinner();
  document.body.appendChild(loader);

  try {
    await validateUserAndLoadProfile({ shouldLogout: true });
    // User validation and profile loading errors are handled within the function
  } catch (error) {
    console.error('Error during user validation:', error);
    // TODO: Implement user-facing error notification
  } finally {
    setupRouter(app);
    setupNaiveUIMeta();

    // remove the loading spinner
    document.body.removeChild(loader);

    app.mount('#app');
  }
}

// starting the app
initApp().catch(err => {
  console.error('Critical error during app initialization:', err);
  // TODO: Implement global error handling logic
  // should be displaying a user-friendly error message or fallback UI
});
