import AXIOS_INSTANCE from '@/api/axios.config';
import { catchError } from '@/helpers/validation';
import { defineStore, storeToRefs } from 'pinia';
import { useNewProfileStore } from '../profile';
import { BILLING_PACKAGES_OPTIONS_NEW } from '@/global/globalVariables';

export const usePaymentsStore = defineStore('payments', {
  state: () => ({
    plans: [],
    nextInvoice: {},
    packages: [],
    paymentInfo: {},
    billingProfile: {},
    transactionHistory: [],
    loadingKeys: []
  }),

  getters: {
    getPlans() {
      return this.plans;
    },
    getNextInvoice() {
      return this.nextInvoice;
    },

    // packages getters
    getPackages() {
      return this.packages;
    },
    getTextIvrPackage() {
      // text ivr package object
      return this.packages.find(packageItem => packageItem.type === BILLING_PACKAGES_OPTIONS_NEW.ivrPackage.value) || {};
    },
    getTextIvrSelectedPlan() {
      // finding the selected plan from the text ivr package plans
      return this.getTextIvrPackage?.plans?.find(plan => plan.id === this.paymentInfo?.extentionsTextIvrPackagePlanId) || {};
    },
    getTextIvrSelectedPlanPrice() {
      // getting the selected plan price based on the interval, either yearly or monthly price
      const interval = this.paymentInfo?.interval;
      let price = '-';
      if (interval === 'monthly') {
        price = this.getTextIvrSelectedPlan?.monthlyPrice;
      } else if (interval === 'yearly') {
        price = this.getTextIvrSelectedPlan?.yearlyPrice;
      }
      return price;
    },
    getTextIvrSelectedNextPlan() {
      // finding the selected next plan from the text ivr package plans
      return this.getTextIvrPackage?.plans?.find(plan => plan.id === this.paymentInfo?.extentionsTextIvrPackageNextPlanId) || {};
    },
    getTextIvrSelectedNextPlanPrice() {
      // getting the selected next plan price based on the interval, either yearly or monthly price
      const interval = this.paymentInfo?.interval;
      let price = '-';
      if (interval === 'monthly') {
        price = this.getTextIvrSelectedNextPlan?.monthlyPrice;
      } else if (interval === 'yearly') {
        price = this.getTextIvrSelectedNextPlan?.yearlyPrice;
      }
      return price;
    },
    // text ivr package statuses
    istextIvrPackageActive() {
      // text ivr plan selected for the first time
      return (
        !!this.paymentInfo?.extentionsTextIvrPackagePlanId &&
        !this.paymentInfo?.extentionsTextIvrPackageEndDate &&
        !this.paymentInfo?.extentionsTextIvrPackageNextPlanId
      );
    },
    isTextIvrPackageCanceled() {
      // text ivr plan canceled
      return (
        !!this.paymentInfo?.extentionsTextIvrPackagePlanId &&
        !!this.paymentInfo?.extentionsTextIvrPackageEndDate &&
        !this.paymentInfo?.extentionsTextIvrPackageNextPlanId
      );
    },
    istextIvrPackageActiveAndNextPlan() {
      // text ivr plan is active and there is a next plan selected
      return (
        !!this.paymentInfo?.extentionsTextIvrPackagePlanId &&
        !!this.paymentInfo?.extentionsTextIvrPackageEndDate &&
        !!this.paymentInfo?.extentionsTextIvrPackageNextPlanId
      );
    },

    getAiTextFollowUpPackage() {
      return this.packages.find(packageItem => packageItem.type === BILLING_PACKAGES_OPTIONS_NEW.aiTextFollowUp.value) || {};
    },
    getAiTextFollowUpSelectedPlan() {
      // finding the selected plan from the ai text fup package plans
      return this.getAiTextFollowUpPackage?.plans?.find(plan => plan.id === this.paymentInfo?.extentionsAiTextFollowUpsPlanId) || {};
    },
    getAiTextFollowUpSelectedPlanPrice() {
      // getting the selected plan price based on the interval, either yearly or monthly price
      const interval = this.paymentInfo?.interval;
      let price = '-';
      if (interval === 'monthly') {
        price = this.getAiTextFollowUpSelectedPlan?.monthlyPrice;
      } else if (interval === 'yearly') {
        price = this.getAiTextFollowUpSelectedPlan?.yearlyPrice;
      }
      return price;
    },
    getAiTextFollowUpSelectedNextPlan() {
      // finding the selected next plan from the ai text fup package plans
      return this.getAiTextFollowUpPackage?.plans?.find(plan => plan.id === this.paymentInfo?.extentionsAiTextFollowUpsNextPlanId) || {};
    },
    getAiTextFollowUpSelectedNextPlanPrice() {
      // getting the selected next plan price based on the interval, either yearly or monthly price
      const interval = this.paymentInfo?.interval;
      let price = '-';
      if (interval === 'monthly') {
        price = this.getAiTextFollowUpSelectedNextPlan?.monthlyPrice;
      } else if (interval === 'yearly') {
        price = this.getAiTextFollowUpSelectedNextPlan?.yearlyPrice;
      }
      return price;
    },
    // ai text fup package statuses
    isAiTextFollowUpPackagective() {
      // ai text fup plan selected for the first time
      return (
        !!this.paymentInfo?.extentionsAiTextFollowUpsPlanId &&
        !this.paymentInfo?.extentionsAiTextFollowUpsEndDate &&
        !this.paymentInfo?.extentionsAiTextFollowUpsNextPlanId
      );
    },
    isAiTextFollowUpPackageCanceled() {
      // ai text fup plan canceled
      return (
        !!this.paymentInfo?.extentionsAiTextFollowUpsPlanId &&
        !!this.paymentInfo?.extentionsAiTextFollowUpsEndDate &&
        !this.paymentInfo?.extentionsAiTextFollowUpsNextPlanId
      );
    },
    isAiTextFollowUpPackageActiveAndNextPlan() {
      // ai text fup plan is active and there is a next plan selected
      return (
        !!this.paymentInfo?.extentionsAiTextFollowUpsPlanId &&
        !!this.paymentInfo?.extentionsAiTextFollowUpsEndDate &&
        !!this.paymentInfo?.extentionsAiTextFollowUpsNextPlanId
      );
    },

    // google ads package
    getGoogleAdsPackage() {
      return this.packages.find(packageItem => packageItem.type === BILLING_PACKAGES_OPTIONS_NEW.googleAds.value) || {};
    },
    getGoogleAdsSelectedPlan() {
      // finding the selected plan from the google ads package plans
      return this.getGoogleAdsPackage?.plans?.find(plan => plan.id === this.paymentInfo?.extentionsGoogleAdsPlanId) || {};
    },
    getGoogleAdsSelectedPlanPrice() {
      // getting the selected plan price based on the interval, either yearly or monthly price
      const interval = this.paymentInfo?.interval;
      let price = '-';
      if (interval === 'monthly') {
        price = this.getGoogleAdsSelectedPlan?.monthlyPrice;
      } else if (interval === 'yearly') {
        price = this.getGoogleAdsSelectedPlan?.yearlyPrice;
      }
      return price;
    },
    getGoogleAdsSelectedNextPlan() {
      // finding the selected next plan from the google ads package plans
      return this.getGoogleAdsPackage?.plans?.find(plan => plan.id === this.paymentInfo?.extentionsGoogleAdsNextPlanId) || {};
    },
    getGoogleAdsSelectedNextPlanPrice() {
      // getting the selected next plan price based on the interval, either yearly or monthly price
      const interval = this.paymentInfo?.interval;
      let price = '-';
      if (interval === 'monthly') {
        price = this.getGoogleAdsSelectedNextPlan?.monthlyPrice;
      } else if (interval === 'yearly') {
        price = this.getGoogleAdsSelectedNextPlan?.yearlyPrice;
      }
      return price;
    },
    // google ads package statuses
    isGoogleAdsPackageActive() {
      // google ads plan selected for the first time
      return (
        !!this.paymentInfo?.extentionsGoogleAdsPlanId &&
        !this.paymentInfo?.extentionsGoogleAdsEndDate &&
        !this.paymentInfo?.extentionsGoogleAdsNextPlanId
      );
    },
    isGoogleAdsPackageCanceled() {
      // google ads plan canceled
      return (
        !!this.paymentInfo?.extentionsGoogleAdsPlanId &&
        !!this.paymentInfo?.extentionsGoogleAdsEndDate &&
        !this.paymentInfo?.extentionsGoogleAdsNextPlanId
      );
    },
    isGoogleAdsPackageActiveAndNextPlan() {
      // google ads plan is active and there is a next plan selected
      return (
        !!this.paymentInfo?.extentionsGoogleAdsPlanId &&
        !!this.paymentInfo?.extentionsGoogleAdsEndDate &&
        !!this.paymentInfo?.extentionsGoogleAdsNextPlanId
      );
    },

    // recruiting landing pages package
    getRecruitingPackage() {
      return this.packages.find(packageItem => packageItem.type === BILLING_PACKAGES_OPTIONS_NEW.recruitingLandingPages.value) || {};
    },
    getRecruitingSelectedPlan() {
      // finding the selected plan from the recruiting landing pages package plans
      return this.getRecruitingPackage?.plans?.find(plan => plan.id === this.paymentInfo?.extentionsRecruitingPagesPlanId) || {};
    },
    getRecruitingSelectedPlanPrice() {
      // getting the selected plan price based on the interval, either yearly or monthly price
      const interval = this.paymentInfo?.interval;
      let price = '-';
      if (interval === 'monthly') {
        price = this.getRecruitingSelectedPlan?.monthlyPrice;
      } else if (interval === 'yearly') {
        price = this.getRecruitingSelectedPlan?.yearlyPrice;
      }
      return price;
    },
    getRecruitingSelectedNextPlan() {
      // finding the selected next plan from the recruiting landing pages package plans
      return this.getRecruitingPackage?.plans?.find(plan => plan.id === this.paymentInfo?.extentionsRecruitingPagesNextPlanId) || {};
    },
    getRecruitingSelectedNextPlanPrice() {
      // getting the selected next plan price based on the interval, either yearly or monthly price
      const interval = this.paymentInfo?.interval;
      let price = '-';
      if (interval === 'monthly') {
        price = this.getRecruitingSelectedNextPlan?.monthlyPrice;
      } else if (interval === 'yearly') {
        price = this.getRecruitingSelectedNextPlan?.yearlyPrice;
      }
      return price;
    },
    // recruiting landing pages package statuses
    isRecruitingPackageActive() {
      // recruiting landing pages plan selected for the first time
      return (
        !!this.paymentInfo?.extentionsRecruitingPagesPlanId &&
        !this.paymentInfo?.extentionsRecruitingPagesEndDate &&
        !this.paymentInfo?.extentionsRecruitingPagesNextPlanId
      );
    },
    isRecruitingPackageCanceled() {
      // recruiting landing pages plan canceled
      return (
        !!this.paymentInfo?.extentionsRecruitingPagesPlanId &&
        !!this.paymentInfo?.extentionsRecruitingPagesEndDate &&
        !this.paymentInfo?.extentionsRecruitingPagesNextPlanId
      );
    },
    isRecruitingPackageActiveAndNextPlan() {
      // recruiting landing pages plan is active and there is a next plan selected
      return (
        !!this.paymentInfo?.extentionsRecruitingPagesPlanId &&
        !!this.paymentInfo?.extentionsRecruitingPagesEndDate &&
        !!this.paymentInfo?.extentionsRecruitingPagesNextPlanId
      );
    },

    // partial to full lead package
    getPartialToFullLeadPackage() {
      return this.packages.find(packageItem => packageItem.type === BILLING_PACKAGES_OPTIONS_NEW.partialToFullLead.value) || {};
    },
    getPartialToFullLeadSelectedPlan() {
      // finding the selected plan from the partial to full lead package plans
      return this.getPartialToFullLeadPackage?.plans?.find(plan => plan.id === this.paymentInfo?.extentionsPartialToFullLeadsPlanId) || {};
    },
    getPartialToFullLeadSelectedPlanPrice() {
      // getting the selected plan price based on the interval, either yearly or monthly price
      const interval = this.paymentInfo?.interval;
      let price = '-';
      if (interval === 'monthly') {
        price = this.getPartialToFullLeadSelectedPlan?.monthlyPrice;
      } else if (interval === 'yearly') {
        price = this.getPartialToFullLeadSelectedPlan?.yearlyPrice;
      }
      return price;
    },
    getPartialToFullLeadSelectedNextPlan() {
      // finding the selected next plan from the partial to full lead package plans
      return (
        this.getPartialToFullLeadPackage?.plans?.find(plan => plan.id === this.paymentInfo?.extentionsPartialToFullLeadsNextPlanId) || {}
      );
    },
    getPartialToFullLeadSelectedNextPlanPrice() {
      // getting the selected next plan price based on the interval, either yearly or monthly price
      const interval = this.paymentInfo?.interval;
      let price = '-';
      if (interval === 'monthly') {
        price = this.getPartialToFullLeadSelectedNextPlan?.monthlyPrice;
      } else if (interval === 'yearly') {
        price = this.getPartialToFullLeadSelectedNextPlan?.yearlyPrice;
      }
      return price;
    },
    // partial to full lead package statuses
    isPartialToFullLeadPackageActive() {
      // partial to full lead plan selected for the first time
      return (
        !!this.paymentInfo?.extentionsPartialToFullLeadsPlanId &&
        !this.paymentInfo?.extentionsPartialToFullLeadsEndDate &&
        !this.paymentInfo?.extentionsPartialToFullLeadsNextPlanId
      );
    },
    isPartialToFullLeadPackageCanceled() {
      // partial to full lead plan canceled
      return (
        !!this.paymentInfo?.extentionsPartialToFullLeadsPlanId &&
        !!this.paymentInfo?.extentionsPartialToFullLeadsEndDate &&
        !this.paymentInfo?.extentionsPartialToFullLeadsNextPlanId
      );
    },
    isPartialToFullLeadPackageActiveAndNextPlan() {
      // partial to full lead plan is active and there is a next plan selected
      return (
        !!this.paymentInfo?.extentionsPartialToFullLeadsPlanId &&
        !!this.paymentInfo?.extentionsPartialToFullLeadsEndDate &&
        !!this.paymentInfo?.extentionsPartialToFullLeadsNextPlanId
      );
    },

    getPlansMaxSavingsPercentage() {
      // function to calculate the maximum savings percentage among all plans between monthly and yearly prices.
      let maxSavingsPercentage = 0;

      this.plans.forEach(plan => {
        // ensuring both prices are defined and greater than zero to avoid division by zero or NaN.
        if (plan.monthlyPrice && plan.yearlyPrice) {
          // calculating the percentage savings.
          const yearlyEquivalentMonthlyPrice = plan.monthlyPrice * 12;
          const savingsPercentage = ((yearlyEquivalentMonthlyPrice - plan.yearlyPrice) / yearlyEquivalentMonthlyPrice) * 100;
          // updating the highest savings percentage if the current one is greater.
          if (savingsPercentage > maxSavingsPercentage) {
            maxSavingsPercentage = savingsPercentage;
          }
        }
      });

      return Math.round(maxSavingsPercentage);
    },
    getPaymentInfo() {
      return this.paymentInfo;
    },
    getTransactionHistory() {
      return this.transactionHistory;
    },
    getBillingProfile() {
      return this.billingProfile;
    },
    getPaymentPlanPeriod() {
      return this.paymentInfo?.interval;
    },
    isPlanPeriodMonthly() {
      return this.paymentInfo?.interval === 'monthly';
    },
    isPlanPeriodYearly() {
      return this.paymentInfo?.interval === 'yearly';
    },
    getPaymentPlanId() {
      return this.paymentInfo?.planId;
    },
    getPaymentPlanMaxListings() {
      return this.paymentInfo?.plan?.maxListings;
    },
    getPaymentListingsCount() {
      return this.paymentInfo?.listingsCount;
    },
    getPaymentPlanListingsCount() {
      return this.paymentInfo?.listingsCount;
    },
    isBillingCardAdded() {
      // card is added if the customerId is present
      return !!this.billingProfile?.customerId;
    },
    getBillToInfo() {
      // Bill to info if the card details are present
      if (this.isBillingCardAdded) {
        return {
          firstName: this.billingProfile?.billToFirstName || null,
          lastName: this.billingProfile?.billToLastName || null,
          address: this.billingProfile?.billToAddress || null,
          city: this.billingProfile?.billToCity || null,
          state: this.billingProfile?.billToState || null,
          zip: this.billingProfile?.billToZip || null,
          country: this.billingProfile?.billToCountry || null,
          cardLastFour: this.billingProfile?.cardLastFour || null,
          verified: this.billingProfile?.verified || false
        };
      } else {
        return {};
      }
    },

    isListingLimitReached() {
      return this.paymentInfo?.reachedListingsLimit;
    },
    isAgentsLimitReached() {
      return this.paymentInfo?.reachedAgentsLimit;
    },

    isPlanStatusActive() {
      return this.paymentInfo?.status?.toLowerCase() === 'active';
    },
    isPlanStatusInactive() {
      return this.paymentInfo?.status?.toLowerCase() === 'inactive';
    },

    isPlanSubStatusIncomplete() {
      // if true, we should redirect to reactivation to add payment and select a plan
      return this.paymentInfo?.subStatus?.toLowerCase() === 'incomplete';
    },
    isPlanSubStatusCanceled() {
      // if true, we show an alert on PrivatLayout which is shown for all private routes
      return this.paymentInfo?.subStatus?.toLowerCase() === 'canceled';
    },
    isPlanSubStatusSuspended() {
      // if true, we show an alert on PrivatLayout which is shown for all private routes
      return this.paymentInfo?.subStatus?.toLowerCase() === 'suspended';
    },
    isPlanSubStatusExpired() {
      // if true, we should redirect to reactivation to add payment and select a plan
      return this.paymentInfo?.subStatus?.toLowerCase() === 'expired';
    },
    isPlanSubStatusTerminated() {
      // if true, we should redirect to reactivation to add payment and select a plan
      return this.paymentInfo?.subStatus?.toLowerCase() === 'terminated';
    },
    getCurrentPlanPrice() {
      // getting the current plan price based on the interval, either yearly or monthly price
      const interval = this.paymentInfo?.interval;
      let price = '-';

      if (interval === 'monthly') {
        price = this.paymentInfo?.plan?.monthlyPrice;
      } else if (interval === 'yearly') {
        price = this.paymentInfo?.plan?.yearlyPrice;
      }

      return price;
    },
    getPlanAmount() {
      return this.paymentInfo?.amount || 0;
    },

    getLoadingKeys() {
      return this.loadingKeys;
    }
  },

  actions: {
    /**
     * Getting the plans based on the user type
     * @param {String} loadingKey
     * @returns {Promise}
     */
    async useGetPlans({ loadingKey }) {
      const newProfileStore = useNewProfileStore();
      const { getUserType } = storeToRefs(newProfileStore);

      const type = getUserType.value?.toUpperCase(); // AGENT, LENDER, BROKER, TEAM

      try {
        this.loadingKeys.push(loadingKey);

        const res = await AXIOS_INSTANCE.get('/plans', {
          params: {
            type
          }
        });

        const data = res.data?.data || res.data;

        // updating the plans
        this.plans = data;

        return {
          success: data,
          error: null
        };
      } catch (error) {
        return {
          success: false,
          error: catchError(error, true)
        };
      } finally {
        this.loadingKeys = this.loadingKeys.filter(key => key !== loadingKey);
      }
    },

    /**
     * Getting the status of the current payment plan if exists, THIS SHOULD BE RUN AFTER EACH useUpdateSubscriptionPlan OR useSubscribePlan
     * @param {String} loadingKey
     * @returns {Promise}
     */
    async useGetPaymentStatus({ loadingKey }) {
      try {
        this.loadingKeys.push(loadingKey);

        const res = await AXIOS_INSTANCE.get('/payments/space/status');

        const data = res.data?.data || res.data;

        // updating the payment info
        this.paymentInfo = data;

        return {
          success: data,
          error: null
        };
      } catch (error) {
        return {
          success: false,
          error: catchError(error, true)
        };
      } finally {
        this.loadingKeys = this.loadingKeys.filter(key => key !== loadingKey);
      }
    },

    /**
     * Refreshing the payment status with the latest info
     * @param {String} loadingKey
     * @returns {Promise}
     * */
    async useRefreshPaymentStatus({ loadingKey }) {
      try {
        this.loadingKeys.push(loadingKey);

        const res = await AXIOS_INSTANCE.post('/payments/space/status');

        const data = res.data?.data || res.data;

        return {
          success: data,
          error: null
        };
      } catch (error) {
        return {
          success: false,
          error: catchError(error, true)
        };
      } finally {
        this.loadingKeys = this.loadingKeys.filter(key => key !== loadingKey);
      }
    },

    /**
     * Subscribing to a plan
     * @param {Object} payload - ex: { period: 'monthly', planId: 123 }
     * @param {String} loadingKey
     * @returns {Promise}
     */
    async useSubscribePlan({ payload, loadingKey }) {
      try {
        this.loadingKeys.push(loadingKey);

        const res = await AXIOS_INSTANCE.post('/payments/space/subscribe', payload);

        const data = res.data?.data || res.data;

        // calling useGetPaymentStatus to update the payment info
        await this.useGetPaymentStatus({ loadingKey });

        return {
          success: data,
          error: null
        };
      } catch (error) {
        let errorDetails = catchError(error, true);

        if (error?.data?.error) {
          // overriding the error message with the server error message
          errorDetails = error?.data?.error?.message;
        }

        return {
          success: false,
          error: errorDetails
        };
      } finally {
        this.loadingKeys = this.loadingKeys.filter(key => key !== loadingKey);
      }
    },

    /**
     * Updating the subscription plan, we update if subscriptionId exists other wise use useSubscribePlan method above
     * NOTE: DATA RESPONSE SERVER SHOULD BE TAKEN INTO ACCOUNT, ALWAYS USE useGetPaymentStatus AFTER THIS METHOD
     * @param {Object} payload - ex: { period: 'monthly', planId: 123, paymentData: { type: "credit_card", billTo: { firstName: "Abdelkarim", lastName: "CHAMLAL", address: "Bentaib", city: "Bentaib", state: "Oujda", zip: "62100", country: "Morocco" } } }
     * @param {String} loadingKey
     *  @returns {Promise}
     * */
    async useUpdateSubscriptionPlan({ payload, loadingKey }) {
      try {
        this.loadingKeys.push(loadingKey);

        const res = await AXIOS_INSTANCE.put('/payments/space/subscribe', payload);

        const data = res.data?.data || res.data;

        // calling useGetPaymentStatus to update the payment info
        await this.useGetPaymentStatus({ loadingKey });
        await this.useGetNextInvoice({ loadingKey });

        return {
          success: data,
          error: null
        };
      } catch (error) {
        let errorDetails = catchError(error, true);

        if (error?.data?.error) {
          // overriding the error message with the server error message
          errorDetails = error?.data?.error?.message;
        }

        return {
          success: false,
          error: errorDetails
        };
      } finally {
        this.loadingKeys = this.loadingKeys.filter(key => key !== loadingKey);
      }
    },

    async useDeleteSubscription({ payload, loadingKey } = {}) {
      try {
        this.loadingKeys.push(loadingKey);

        const res = await AXIOS_INSTANCE.delete('/payments/space/subscribe', {
          data: payload
        });

        const data = res.data?.data || res.data;

        // calling useGetPaymentStatus to update the payment info
        await this.useGetPaymentStatus({ loadingKey });

        return {
          success: data,
          error: null
        };
      } catch (error) {
        let errorDetails = catchError(error, true);

        if (error?.data?.error) {
          // overriding the error message with the server error message
          errorDetails = error?.data?.error?.message;
        }

        return {
          success: false,
          error: errorDetails
        };
      } finally {
        this.loadingKeys = this.loadingKeys.filter(key => key !== loadingKey);
      }
    },

    async useGetNextInvoice({ loadingKey }) {
      try {
        this.loadingKeys.push(loadingKey);

        const res = await AXIOS_INSTANCE.get('/payments/space/next-invoice');

        const data = res.data?.data || res.data;

        // updating the next invoice
        this.nextInvoice = data;

        return {
          success: data,
          error: null
        };
      } catch (error) {
        return {
          success: false,
          error: catchError(error, true)
        };
      } finally {
        this.loadingKeys = this.loadingKeys.filter(key => key !== loadingKey);
      }
    },

    /**
     * Creating a package subscription. If a package doesn't have an id extentions[PACKAGE_NAME]PlanId
     * we send this subscribe request, otherwise we send useUpdatePackageSubscription
     * @param {Object} payload
     * @param {String} loadingKey
     * @returns {Promise}
     * */
    async useCreatePackageSubscription({ payload, loadingKey } = {}) {
      try {
        this.loadingKeys.push(loadingKey);

        const res = await AXIOS_INSTANCE.post('/packages', payload);

        const data = res.data?.data || res.data;

        // calling useGetPaymentStatus to update the payment info
        await this.useGetPaymentStatus({ loadingKey });

        return {
          success: data,
          error: null
        };
      } catch (error) {
        let errorDetails = catchError(error, true);

        if (error?.data?.error) {
          // overriding the error message with the server error message
          errorDetails = error?.data?.error?.message;
        }

        return {
          success: false,
          error: errorDetails
        };
      } finally {
        this.loadingKeys = this.loadingKeys.filter(key => key !== loadingKey);
      }
    },

    /**
     *  Updating a package subscription. If a package has an id extentions[PACKAGE_NAME]PlanId
     * we send this update request, otherwise we send useCreatePackageSubscription
     * @param {Object} payload
     * @param {String} loadingKey
     * @returns {Promise}
     */
    async useUpdatePackageSubscription({ payload, loadingKey } = {}) {
      try {
        this.loadingKeys.push(loadingKey);

        const res = await AXIOS_INSTANCE.put('/packages', payload);

        const data = res.data?.data || res.data;

        // calling useGetPaymentStatus to update the payment info
        await this.useGetPaymentStatus({ loadingKey });

        return {
          success: data,
          error: null
        };
      } catch (error) {
        let errorDetails = catchError(error, true);

        if (error?.data?.error) {
          // overriding the error message with the server error message
          errorDetails = error?.data?.error?.message;
        }

        return {
          success: false,
          error: errorDetails
        };
      } finally {
        this.loadingKeys = this.loadingKeys.filter(key => key !== loadingKey);
      }
    },

    async useCancelPackageSubscription({ payload, loadingKey } = {}) {
      try {
        this.loadingKeys.push(loadingKey);

        const res = await AXIOS_INSTANCE.delete('/packages', {
          data: payload
        });

        const data = res.data?.data || res.data;

        // calling useGetPaymentStatus to update the payment info
        await this.useGetPaymentStatus({ loadingKey });

        return {
          success: data,
          error: null
        };
      } catch (error) {
        let errorDetails = catchError(error, true);

        if (error?.data?.error) {
          // overriding the error message with the server error message
          errorDetails = error?.data?.error?.message;
        }

        return {
          success: false,
          error: errorDetails
        };
      } finally {
        this.loadingKeys = this.loadingKeys.filter(key => key !== loadingKey);
      }
    },

    /**
     * Getting the billing profile info like card details, bank details etc
     * @param {String} loadingKey
     * @returns {Promise}
     */
    async useGetBillingProfile({ loadingKey }) {
      try {
        this.loadingKeys.push(loadingKey);

        const res = await AXIOS_INSTANCE.get('/payments/profile');

        const data = res.data?.data || res.data;

        // updating the billing profile
        this.billingProfile = data;

        return {
          success: data,
          error: null
        };
      } catch (error) {
        return {
          success: false,
          error: catchError(error, true)
        };
      } finally {
        this.loadingKeys = this.loadingKeys.filter(key => key !== loadingKey);
      }
    },

    /**
     * Updating the billing profile info like card details, bank details etc
     * @param {Object} payload - ex: { "type": "credit_card", "billTo": { "firstName": "Abdelkarim", "lastName": "CHAMLAL", "address": "Bentaib", "city": "Bentaib", "state": "Oujda", "zip": "62100", "country": "Morocco" }, "cardDetails": { "cardNumber": "4012888818888", "expMonth": "06", "expYear": "2029", "cvc": "154" } }
     * @param {String} loadingKey
     * @returns {Promise}
     */
    async useUpdateBillingProfile({ payload, loadingKey }) {
      try {
        this.loadingKeys.push(loadingKey);

        const res = await AXIOS_INSTANCE.put('/payments/profile', payload);

        const data = res.data?.data || res.data;

        // updating the billing profile
        this.billingProfile = data;

        return {
          success: data,
          error: null
        };
      } catch (error) {
        return {
          success: false,
          error: catchError(error, true)
        };
      } finally {
        this.loadingKeys = this.loadingKeys.filter(key => key !== loadingKey);
      }
    },

    async useGetPackages({ loadingKey }) {
      try {
        this.loadingKeys.push(loadingKey);

        const res = await AXIOS_INSTANCE.get('/packages');

        const data = res.data?.data || res.data;

        // updating the packages
        this.packages = data;

        return {
          success: data,
          error: null
        };
      } catch (error) {
        return {
          success: false,
          error: catchError(error, true)
        };
      } finally {
        this.loadingKeys = this.loadingKeys.filter(key => key !== loadingKey);
      }
    },

    /**
     * Getting the transaction history
     * @param {String} loadingKey
     * @returns {Promise}
     * */
    async useGetTransactionHistory({ loadingKey }) {
      try {
        this.loadingKeys.push(loadingKey);

        const res = await AXIOS_INSTANCE.get('/payments/transactions');

        const data = res.data?.data || res.data;

        // updating the transaction history
        this.transactionHistory = data;

        return {
          success: data,
          error: null
        };
      } catch (error) {
        return {
          success: false,
          error: catchError(error, true)
        };
      } finally {
        this.loadingKeys = this.loadingKeys.filter(key => key !== loadingKey);
      }
    },

    useCheckIsPlanSelected({ planId, period } = {}) {
      const currentPlanId = this.paymentInfo?.planId;
      const currentInterval = this.paymentInfo?.interval; // monthly or yearly

      // checking if the planId is the same as the current plan and the interval is the same and the status is active

      return currentPlanId === planId && currentInterval === period && this.isPlanStatusActive;
    },

    useDeterminePlanPrice(monthlyPrice = '', yearlyPrice = '', interval) {
      let price = '-';
      const currentInterval = interval || this.paymentInfo?.interval;

      if (currentInterval === 'monthly') {
        price = monthlyPrice;
      } else if (currentInterval === 'yearly') {
        price = yearlyPrice;
      }

      return price;
    },

    useGetMaxListingCount(maxListingCount) {
      // checking if the maxListingCount is -1 which means unlimited, otherwise return the maxListingCount
      return maxListingCount === -1 ? 'Unlimited' : maxListingCount;
    },

    // reset the store
    useResetStore() {
      this.$reset();
    }
  }
});
