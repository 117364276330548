import { useNewProfileStore } from '@/stores/profile';
import { useAuthStore } from '@/stores/auth';
import { usePaymentsStore } from '@/stores/payments';
import { REACTIVATE_ACCOUNT_ROUTE_NAME, SIGNUP_COMPLETE_ROUTE_NAME } from '@/global/globalVariables';
import router from '@/router';

/**
 * Validates the user's authentication status and loads their profile data.
 * This is the first function called during app initialization.
 *
 * @param {Object} options - The options object
 * @param {boolean} [options.shouldLogout=true] - Whether to log out, it's flagged true from main.js since if there is an error in user validation, the user should be logged out
 * @throws {Error} If there's an issue with profile data retrieval
 * @returns {Promise<void>}
 */
export async function validateUserAndLoadProfile({ shouldLogout = false } = {}) {
  const profileStore = useNewProfileStore();
  const authStore = useAuthStore();
  const paymentsStore = usePaymentsStore();

  let shouldProceed = false; // controls whether to proceed with further actions

  if (!authStore.getToken) {
    return shouldProceed;
  }

  try {
    const { error, success } = await profileStore.useGetProfileGeneralData({ loadingKey: '' });

    if (error) {
      if (shouldLogout) {
        authStore.useLogout();
      }
      // TODO: Implement global error handling logic
      throw new Error('User validation failed, logging out user');
    }

    if (success) {
      // getting the payment status
      const { error } = await paymentsStore.useGetPaymentStatus({ loadingKey: '' });

      if (error) {
        console.error('Failed to load payment status');
      }
    }
  } finally {
    if (profileStore.getIsUserOnboarding) {
      // if user is still onboarding
      router.push({ name: SIGNUP_COMPLETE_ROUTE_NAME });
      shouldProceed = false;
    } else if (paymentsStore.isPlanStatusInactive) {
      // if user's plan status is inactive
      router.push({ name: REACTIVATE_ACCOUNT_ROUTE_NAME });
      shouldProceed = false;
    } else {
      shouldProceed = true;
    }
  }

  return shouldProceed;
}

/**
 * Creates and returns a DOM element representing a loading spinner.
 * This is used because Naive UI is not yet loaded at this point.
 *
 * @returns {HTMLElement} The loading spinner element
 */
export function createLoadingSpinner() {
  const loader = document.createElement('div');
  loader.className = 'full-loader';
  loader.innerHTML = `
    <div class="full-loader-spinner"></div>
    <div>Loading...</div>
  `;
  return loader;
}
