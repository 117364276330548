import AXIOS_INSTANCE from '@/api/axios.config';
import { defineStore } from 'pinia';

export const useNewProfileStore = defineStore('new-profile', {
  state: () => ({
    // auth
    type: null, // controls the type of user, agent, broker, team
    isOnboarding: false,

    // profile
    profileGeneralInformation: {
      profileImage: null,
      firstName: null,
      lastName: null,
      title: null,
      licenseNumber: null,
      licenseStates: [],
      bio: null
    },
    profileCompanyInformation: {
      reversedLogo: null,
      normalLogo: null,
      companyBroker: null,
      teamName: null,
      useTeamName: null,
      street: null,
      city: null,
      state: null,
      zip: null,
      country: null
    },
    profileContacts: [],
    socials: [],
    user: {
      username: null,
      email: null
    },
    notification: {},
    userPayment: {},
    loadingKeys: [],
    // a user object that is used to update the user data
    userData: {
      firstName: null,
      lastName: null,
      username: null,
      email: null,
      profileImage: null
    },
    // modals
    socialProfilesModal: {
      showModal: false,
      title: null,
      button: null,
      data: null,
      type: null,
      acceptedTypes: ['add', 'edit', 'close']
    },
    // config
    config: {
      // THIS HELPS TO ORGANIZE THE DATA SINCE BACKEND AND DESIGN ARE NOT FORMATED THE SAME
      supportedObjectNames: ['general', 'company', 'socials', 'profileContacts', 'user', 'notification', 'userPayment']
    }
  }),

  getters: {
    getLoadingKeys() {
      return this.loadingKeys;
    },
    getProfileGeneralInformation() {
      return this.profileGeneralInformation;
    },
    getProfileCompanyInformation() {
      return this.profileCompanyInformation;
    },
    getProfileContacts() {
      return this.profileContacts;
    },
    getProfileSocials() {
      return this.socials;
    },
    getUser() {
      return this.user;
    },
    getNotification() {
      return this.notification;
    },
    getUserPayment() {
      return this.userPayment;
    },
    getUserData() {
      return this.userData;
    },
    getUserType() {
      return this.type?.toLowerCase();
    },
    getIsUserBroker() {
      return this.type?.toLowerCase() === 'broker';
    },
    getIsUserAgent() {
      return this.type?.toLowerCase() === 'agent';
    },
    getIsUserOnboarding() {
      return this.isOnboarding;
    },

    // modals
    getSocialProfilesModal() {
      return this.socialProfilesModal;
    }
  },

  actions: {
    async useGetProfileGeneralData({ loadingKey }) {
      try {
        this.loadingKeys.push(loadingKey);
        const response = await AXIOS_INSTANCE.get(`/profile?with=user,socials,profile,profileContacts,notification,company`);

        const data = response.data?.data || response.data;

        this.useAssignResponseToStore({ objectName: 'general', response: data });
        this.useAssignResponseToStore({ objectName: 'company', response: data });
        this.useAssignResponseToStore({ objectName: 'profileContacts', response: data });
        this.useAssignResponseToStore({ objectName: 'socials', response: data });
        this.useAssignResponseToStore({ objectName: 'user', response: data });
        this.useAssignResponseToStore({ objectName: 'notification', response: data });
        this.useAssignResponseToStore({ objectName: 'userPayment', response: data });

        return {
          success: response.data,
          error: null,
          status: response.status
        };
      } catch (error) {
        return {
          success: false,
          error: error?.data,
          status: error?.status
        };
      } finally {
        this.loadingKeys = this.loadingKeys.filter(key => key !== loadingKey);
      }
    },

    // checking if the objectName is supported
    useValidateObjectName({ objectName }) {
      if (!this.config.supportedObjectNames.includes(objectName)) {
        throw new Error(`You passed an invalid objectName: ${objectName}, supported objectNames are: ${this.config.supportedObjectNames}`);
      }
    },

    async useUpdateProfileData({ loadingKey, objectName, payload }) {
      try {
        this.loadingKeys.push(loadingKey);
        const response = await AXIOS_INSTANCE.put(`/profile`, payload);
        const data = response.data?.data || response.data;

        this.useAssignResponseToStore({ objectName, response: data });

        return {
          success: response.data,
          error: null
        };
      } catch (error) {
        return {
          success: false,
          error: error?.data
        };
      } finally {
        this.loadingKeys = this.loadingKeys.filter(key => key !== loadingKey);
      }
    },

    // function that take response and assign it to the store
    useAssignResponseToStore({ objectName, response }) {
      // validating the passed objectName
      try {
        this.useValidateObjectName({ objectName });
      } catch (error) {
        return console.error(error);
      }

      // always updating the general information
      this.profileGeneralInformation = {
        profileImage: response?.profileImage,
        firstName: response?.firstName,
        lastName: response?.lastName,
        title: response?.title,
        licenseNumber: response?.licenseNumber,
        licenseStates: response?.licenseStates,
        bio: response?.bio,
        slug: response?.slug
      };
      // upating the type
      this.type = response?.type;
      // updating the onboarding
      this.isOnboarding = response?.isOnboarding;

      if (objectName === 'company') {
        this.profileCompanyInformation = {
          reversedLogo: response?.reversedLogo,
          normalLogo: response?.normalLogo,
          companyBroker: response?.companyBroker,
          teamName: response?.teamName,
          useTeamName: response?.useTeamName,
          street: response?.street,
          city: response?.city,
          state: response?.state,
          zip: response?.zip,
          country: response?.country
        };
      } else if (objectName === 'profileContacts') {
        this.profileContacts = response?.profileContacts;
      } else if (objectName === 'socials') {
        this.socials = response?.socials;
      } else if (objectName === 'user') {
        this.user = response?.user;
      } else if (objectName === 'notification') {
        this.notification = response?.notification;
      } else if (objectName === 'userPayment') {
        this.userPayment = response?.space?.payment;
      }

      // updating the userData object
      this.userData = {
        username: this.user.username || null,
        email: this.user.email || null,
        firstName: this.profileGeneralInformation.firstName || null,
        lastName: this.profileGeneralInformation.lastName || null,
        bio: this.profileGeneralInformation.bio || null,
        profileImage: this.profileGeneralInformation.profileImage || null,
        normalLogo: this.profileCompanyInformation.normalLogo || null,
        teamName: this.profileCompanyInformation.teamName || null,
        companyBroker: this.profileCompanyInformation.companyBroker || null,
        licenseNumber: this.profileGeneralInformation.licenseNumber || null,
        licenseStates: this.profileGeneralInformation.licenseStates || [],
        title: this.profileGeneralInformation.title || null,
        contacts: this.profileContacts || [],
        socials: this.socials || [],
        slug: this.profileGeneralInformation?.slug || null,
        userPayment: this.userPayment || {}
      };
    },

    setModalData({ modalName = null, showModal = false, modalTitle = null, modalButton = null, modalData = null, modalType = null }) {
      if (modalName.toLowerCase() === 'social-profiles-modal') {
        // validating the modal type
        if (!this.socialProfilesModal.acceptedTypes.includes(modalType)) {
          return console.error(
            `You passed an invalid modalType: ${modalType}, accepted types are: ${this.socialProfilesModal.acceptedTypes}`
          );
        }
        const initalData = {
          type: null
        };

        this.socialProfilesModal.showModal = showModal;
        this.socialProfilesModal.title = modalTitle;
        this.socialProfilesModal.button = modalButton;
        this.socialProfilesModal.data = modalData || initalData;
        this.socialProfilesModal.type = modalType;
      }
    }
  }
});
